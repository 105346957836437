import React, { useState } from 'react';
import './App.css';

const isDigit = (c: string) => {
  return c >= '0' && c <= '9';
}

const isDuration = (s: string) => {
  const tokens = s.split(":");
  if (tokens.length !== 2 || tokens[0].length < 1 || tokens[1].length !== 2) {
    return false;
  }
  for (const char of tokens[0]) {
    if (!isDigit(char)) {
      return false;
    }
  }
  for (const char of tokens[1]) {
    if (!isDigit(char)) {
      return false;
    }
  }
  if (Number(tokens[1]) >= 60) {
    return false;
  }
  return true;
};

const MMSStoSeconds = (minutes: number, seconds: number) => {
  return 60 * minutes + seconds;
}

const secondsToDuration = (seconds: number) => {
  let hours = 0;
  let minutes = 0;
  while (seconds >= 60) {
    minutes += 1;
    seconds -= 60;
  }
  if (minutes < 60) {
    return minutes.toString() + ":" + seconds.toFixed(0).padStart(2, '0');
  }
  while (minutes >= 60) {
    hours += 1;
    minutes -= 60;
  }
  return hours.toString() + ":" + minutes.toFixed(0).padStart(2, '0');
}

// https://jsfiddle.net/r46Lfnj0/12/
// TODO: Allow input of any duration.
function App() {
  const [duration, setDuration] = useState("6:00");
  const [lastValidDuration, setLastValidDuration] = useState("6:00");

  const valid = isDuration(duration);
  let errorStyle = {};
  if (valid && duration !== lastValidDuration) {
    setLastValidDuration(duration);
  }
  
  if (!valid) {
    errorStyle = {
      border: "1px solid red"
    };
  }

  const [min, sec] = lastValidDuration.split(":").map(Number);
  const kilometresPerHour = 1 / (min + (sec / 60)) * 60;
  const milesPerHour = 0.621371 * kilometresPerHour;
  const secondsPerK = MMSStoSeconds(min, sec);
  const fiveK = secondsToDuration(5 * secondsPerK);
  const tenK = secondsToDuration(10 * secondsPerK);
  const halfMarathon = secondsToDuration(21.1 * secondsPerK);
  const marathon = secondsToDuration(42.2 * secondsPerK);
  const result = (<>
    <div>A pace of <span style={{ fontWeight: "bold" }}>{lastValidDuration}</span> gives...</div>
    <div><span style={{ fontWeight: "bold" }}>{kilometresPerHour.toFixed(1)}</span> km/h</div>
    <div><span style={{ fontWeight: "bold" }}>{milesPerHour.toFixed(1)}</span> mph</div>
    <div><span style={{ fontWeight: "bold" }}>{fiveK}</span> 5k</div>
    <div><span style={{ fontWeight: "bold" }}>{tenK}</span> 10k</div>
    <div><span style={{ fontWeight: "bold" }}>{halfMarathon}</span> Half Marathon</div>
    <div><span style={{ fontWeight: "bold" }}>{marathon}</span> Marathon</div>
  </>);

  return (
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", height: "100%", justifySelf: "center"}}>
      <div>
        {result}
      </div>
      <div style={{ alignSelf: "stretch", paddingTop: 100 }}>
        Your Pace
      </div>
      <div style={{ alignSelf: "stretch", paddingBottom: 0}}>
        <input style={{ marginBottom: 0, ...errorStyle}} type="text" value={duration} 
        onChange={e => {
          setDuration(e.target.value);
        }}
        onFocus={e => { window.scrollTo(0, 0); document.body.scrollTop = 0; e.preventDefault(); }}
        />
      </div>
    </div>
  );
}

export default App;
